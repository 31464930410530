@import "src/styles/colors";

.self-discovery-item-audio-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.self-discovery-item-audio {
  width: 460px;
}

.self-discovery-item-edit-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 720px;
}

.self-discovery-item-type-select {
  width: 300px;
}

.overview-item-edit-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.overview-item-edit-video-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.feedback-question-option-list-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.option-list-title {
  margin: 0;
}

.option-item-container {
  display: flex;
  align-items: center;
}


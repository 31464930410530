@import "src/styles/colors";

.self-discovery-item-media-list-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.self-discovery-item-media-list {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid $grey-0;
  background-color: $white;
  min-width: 530px;
  min-height: 100px;
  max-height: 258px;
  overflow: auto;

  &.invalid {
    border-color: $red-error;
  }
}

.therapy-matching-item-edit-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 960px;
}

.therapy-matching-item-type-select {
  width: 300px;
}

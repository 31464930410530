@font-face {
  font-family: 'Ogg';
  src: url('../assets/fonts/Ogg-Roman.woff') format('woff');
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-VariableFont_slnt,wght.ttf') format('truetype');
}

.product-details {
  .editable-label-container {
    align-items: flex-start;
  }
}

.product-general-details {
  display: flex;
  align-items: flex-start;
  gap: 40px;
}

.product-images-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.product-details-image {
  max-width: 480px;
  max-height: 240px;
}

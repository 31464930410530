.skeleton {
  .skeleton-h5 {
    font-size: 2rem;
    width: 200px;
    margin-bottom: 8px;
  }

  .skeleton-media {
    width: 320px;
    height: 160px;
  }
}

.skeleton-h4 {
  font-size: 3rem;
  width: 220px;
}

.skeleton-short-line {
  width: 200px;
}

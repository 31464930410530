@import "assets/ckeditor5/fonts";
@import "styles/common";

body {
  margin: 0;
  font-family: Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $default-color;
  background-color: $bg-beige;
}

.feedback-question-item-edit-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 600px;
}

.feedback-question-option-type-select {
  width: 300px;
}

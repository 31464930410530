@import "src/styles/colors";

.add-therapy-approach-section {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.add-therapy-approach-form-container {
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;
  box-shadow: 0 0 5px $grey-0;
  background-color: $white;
  border-radius: 6px;
}

.add-therapy-approach-form-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.static-page-editor, .static-page-editor-controls {
  display: flex;
  gap: 16px;
}

.static-page-editor {
  flex-direction: column;
}

.static-page-editor-controls {
  align-items: baseline;
}

@import "src/styles/colors";

.addresses-list {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
}

.addresses-list-item {
  position: relative;
  padding: 32px 24px 24px;
  border-radius: 4px;
  box-shadow: 1px 4px 15px -5px $black;

  &:not(.default) .default-address-label {
    display: none;
  }

  &.default {
    box-shadow: 1px 4px 15px -5px $green;
  }
}

.default-address-label {
  position: absolute;
  font-size: 13px;
  top: 0;
  left: 0;
  right: 0;
  padding: 4px 8px;
  border-radius: 4px 4px 0 0;
  background-color: $green;
  color: $white;
}

.address-data-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 300px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

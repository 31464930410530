.add-product-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 350px;
  margin-left: 40px;
  margin-top: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
}

@import "src/styles/colors";

.self-discovery-item-image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 80px;
  border-radius: 6px;
  border: 1px solid $grey-0;
}

.self-discovery-item-image {
  max-width: 120px;
  max-height: 80px;
}

.image-delete-button-container {
  position: absolute;
  top: 2px;
  right: 2px;
  background-color: $white;
  border-radius: 16px;
  border: 1px solid $grey-0;
}

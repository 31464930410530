@import "colors";
@import "fonts";
@import "common/skeleton";
@import "common/headers";

.html-editor-error-message, .validation-error-message {
  color: $red-error;
  font-family: Inter;
  font-size: 0.75rem;
  letter-spacing: 0.03333em;
  line-height: 1.66;
}

.html-editor-error-message {
  margin: 0;
}

.validation-error-message {
  margin: 3px 0 0 14px;
}

.autocompletes-container {
  display: flex;
  gap: 16px;
  margin: 16px 0;
}

.MuiDataGrid-root.error {
  border-color: $red-error;
}
